<template>
  <div class="d-flex align-center" v-if="pSelectedTeam">
    <v-chip
      class="team-status-chip"
      :class="chipColor"
    >
      <span class="team-status-name">{{ pSelectedTeam.getTeamDisplayStatus() }}</span>

      <v-tooltip top v-if="iconBtn">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="ml-2 team-status-icon"
            color="white"
            v-bind="attrs"
            v-on="on"
            :disabled="loading || pLoading"
            @click="openConfirmationDialog()"
          >
            {{ iconBtn }}
          </v-icon>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </v-chip>
    <ConfirmationDialog ref="activateOrDeactivateDialog" />
  </div>
</template>

<script>
import Vue from "vue";
import {
  TEAM_DRAFT_STATUS,
  TEAM_ACTIVE_STATUS,
  TEAM_PAUSED_STATUS, TEAM_INTERIM_STATUS, TEAM_TERMINATED_STATUS, logAndExtractMessage, dismissAction,
} from "../../utils/utils";

export default {
  name: "TeamStatusComponent",

  components: {
    ConfirmationDialog: () => import("../dialogs/ConfirmationDialog.vue"),
  },

  props: {
    pSelectedTeam: Object,
    pLoading: Boolean,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    iconBtn() {
      if (this.pSelectedTeam.status == TEAM_PAUSED_STATUS
          || this.pSelectedTeam.status == TEAM_DRAFT_STATUS
          || this.pSelectedTeam.status == TEAM_INTERIM_STATUS) {
        return "mdi-play-circle";
      }

      if (this.pSelectedTeam.status == TEAM_ACTIVE_STATUS) {
        return "mdi-pause-circle";
      }
      return "";
    },

    tooltipText() {
      if (this.pSelectedTeam.status == TEAM_PAUSED_STATUS
          || this.pSelectedTeam.status == TEAM_DRAFT_STATUS
          || this.pSelectedTeam.status == TEAM_INTERIM_STATUS) {
        return "Activate";
      }

      if (this.pSelectedTeam.status == TEAM_ACTIVE_STATUS) {
        return "Deactivate";
      }
      return "";
    },

    chipColor() {
      if (this.pSelectedTeam.status == TEAM_DRAFT_STATUS) return ["grey", "white--text"];

      if (this.pSelectedTeam.status == TEAM_ACTIVE_STATUS) return ["green", "white--text"];

      if (this.pSelectedTeam.status == TEAM_PAUSED_STATUS) return ["red", "white--text"];

      if (this.pSelectedTeam.status == TEAM_INTERIM_STATUS) return ["purple", "white--text"];

      if (this.pSelectedTeam.status == TEAM_TERMINATED_STATUS) return ["grey darken-4", "white--text"];

      return "";
    },
  },

  methods: {

    openConfirmationDialog() {
      if (this.pSelectedTeam.status == TEAM_PAUSED_STATUS
          || this.pSelectedTeam.status == TEAM_DRAFT_STATUS
          || this.pSelectedTeam.status == TEAM_INTERIM_STATUS) {
        this.openActivateTeamDialog();
      }

      if (this.pSelectedTeam.status == TEAM_ACTIVE_STATUS) {
        this.openDeactivateTeamDialog();
      }
    },

    openActivateTeamDialog() {
      this.$refs.activateOrDeactivateDialog.openDialog({
        header: "Activate confirmation",
        text: `Are you sure you want to activate "${this.pSelectedTeam.name}"?`,
        submitText: "Activate",
        submitColor: "green",
        onSubmit: async () => {
          this.loading = true;
          try {
            await this.$store.dispatch("teamsModule/activateTeam", this.pSelectedTeam);
            await this.$emit("reloadData");
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
          this.loading = false;
        },
      });
    },

    openDeactivateTeamDialog() {
      this.$refs.activateOrDeactivateDialog.openDialog({
        header: "Deactivate confirmation",
        text: `Are you sure you want to deactivate "${this.pSelectedTeam.name}"?`,
        submitText: "Deactivate",
        submitColor: "red",
        onSubmit: async () => {
          this.loading = true;
          try {
            await this.$store.dispatch("teamsModule/deactivateTeam", this.pSelectedTeam);
            await this.$emit("reloadData");
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
          this.loading = false;
        },
      });
    },

  },
};
</script>

<style scoped lang="scss">
.team-status-icon {
  font-size: 20px !important;
}
</style>
